<template>
  <div class="service">
    <van-search v-model="keyword" placeholder="请输入搜索关键词" />
    <div class="serviceList">
      <template v-for="(item,index) in serviceList">
        <div class="serviceDetail" :key="index" @click="changeService(item)">
          <img :src="require(`@/assets/serviceBg/${index > 13 ? index > 27 ? index-27 : index-13 : index+1}.jpg`)" alt="服务背景图" class="fuwu">
          <span class="nowarp content">{{ item.productName }}</span>
        </div>
      </template>
    </div>
    <div class="store">所属用户：{{this.$route.query.username}}</div>
  </div>
</template>
<script>
  import { renderForm } from '@/api/extendedWarranty'
  import storage from '../../utils/storage'
  export default {
    data() {
      return {
        serviceList: [],
        products: [],
        uid: '',
        keyword: '', // 产品搜索关键字
      }
    },
    watch: {
      keyword: { 
        handler(oldV) {
          if (oldV) {
            this.serviceList = this.products.filter(it => it.productName.includes(oldV))
          } else {
            this.serviceList = this.products
          }
        }
      }
    },
    created() {
      this.uid = this.$route.query.userid
      if (this.uid) {
        this.getproduct()
      }
    },
    methods: {
      async getproduct() {
        if (!this.uid) {
          this.$toast.fail('账户ID不能为空')
          return
        }
        const res = await renderForm({ userId: Number(this.uid) })
        if (res && res.success) {
          this.serviceList = res.content.productList
          this.products = res.content.productList
          this.userName = res.content.userName
          this.storeName = res.content.storeName
        }
      },
      changeService(item) {
        // 获取服务年限
        sessionStorage.setItem('changeService', JSON.stringify(item.servicePeriodList))
        const userName = this.$route.query.userName
        const uid = 0
        if (item.productType === 4) {
          const order = storage.getItem('orderInfo')
          order && storage.clearItem('orderInfo')
          this.$router.push({ path: '/extended/create_order', query: { id: uid, userName: userName, productId: item.id, productName: item.productName } })
        } else {
          this.$router.push({ path: '/Catalogue/phoneCatalogue', query: { id: this.uid, productId: item.id, productName: item.productName } })
        }
      }
    }
  }
</script>
<style scoped>
  .store {
    font-size: 14px;
    color: #666;
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .fuwu {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
  }

  .serviceList {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .serviceDetail {
    position: relative;
    /* border: 1px solid #eee; */
    width: 90%;
    height: 130px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 10px;
  }

  .img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    /* background: #fff; */
    /* border-radius: 50%; */
  }

  .content {
    position: absolute;
    width: 90%;
    left: 20px;
    top: 20px;
    /* display: flex; */
    font-size: 18px;
    /* align-items: center; */
  }

  .nowarp {
    white-space: nowrap;
  }
</style>